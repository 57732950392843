export const modules = [
    {
        id: '553105e9-e0e7-43c4-95ed-17f555ea0892',
        name: 'EVENTS',
        label: 'Menu.Events',
        icon: 'calendar',
        route: '/events',
        canRead: false,
        showInMenu: true,
        modules: [
            {
                id: 'bbd6e113-4063-4b4c-a6fd-9f726453e7ed',
                name: 'EVENT',
                label: 'Event_Page.Dashboard',
                route: 'details',
                canRead: false,
                showInMenu: true
            },
            {
                id: '67292f74-501b-48b8-9093-522d846abf0c',
                name: 'VISITORS',
                label: 'Event_Page.Visitors',
                route: 'visitors',
                canRead: false,
                showInMenu: true
            },
            {
                id: '0466754b-e692-48f3-af84-6525a610f548',
                name: 'ANALYTICS',
                label: 'Event_Page.Analytics',
                route: 'analytics',
                canRead: false,
                showInMenu: true
            },
            {
                id: '87e020c5-8fa8-4443-bb01-01b3691bd8cd',
                name: 'DETAILS',
                label: 'Event_Page.Settings',
                route: 'settings',
                canRead: false,
                showInMenu: true
            },
            {
                id: '87e020c5-8fa8-4443-bb01-01b3691bd8cd',
                name: 'PRICE_PLANS',
                label: 'Event_Page.Ticket_Types',
                route: 'price-plans',
                canRead: false,
                showInMenu: true
            },
            {
                id: '87e020c5-8fa8-4443-bb01-01b3691bd8cd',
                name: 'SALE_PLANS',
                label: 'Event_Page.Sale_Plans',
                route: 'sale-plans',
                canRead: false,
                showInMenu: true
            }
        ]
    },
    {
        id: '12bd7cad-74af-44e1-a914-14abc27da390',
        name: 'SUBSCRIPTIONS',
        label: 'Menu.Subscriptions',
        icon: 'creditcard',
        route: '/subscription-types',
        canRead: false,
        showInMenu: true,
        modules: [
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_SUBSCRIPTION_TYPE',
                label: 'Subscription_Type_Detail.Dashboard',
                route: 'details',
                canRead: true,
                showInMenu: true
            },
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_VISITORS',
                label: 'Subscription_Type_Detail.Visitors',
                route: 'visitors',
                canRead: true,
                showInMenu: true
            },
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_ANALYTICS',
                label: 'Subscription_Type_Detail.Analytics',
                route: 'analytics',
                canRead: true,
                showInMenu: true
            },
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_SETTINGS',
                label: 'Subscription_Type_Detail.Settings',
                route: 'settings',
                canRead: true,
                showInMenu: true
            },
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_SUBSCRIPTION_TYPE_PRICES',
                label: 'Subscription_Type_Detail.Subscription_Type_Prices',
                route: 'price-plan',
                canRead: true,
                showInMenu: true
            },
            {
                id: '',
                name: 'SUBSCRIPTION_TYPE_SALE_PLAN',
                label: 'Subscription_Type_Detail.Sale_Plans',
                route: 'sale',
                canRead: true,
                showInMenu: true
            },
        ]
    },
    {
        id: '51db6480-d989-4233-ac62-1a5a76d89758',
        name: 'ORDERS',
        label: 'Menu.Orders',
        icon: 'ticket',
        route: '/orders',
        canRead: false,
        showInMenu: true
    },
    {
        id: 'ST.PAYMENT_LIST',
        name: 'PAYMENTS',
        label: 'Menu.Payments',
        icon: 'currency',
        route: '/payments',
        canRead: true,
        showInMenu: false
    },
    {
        id: '4dddbbac-3d72-42e1-9e9d-d175ebcc3563',
        name: 'RELATIONS',
        label: 'Menu.Relations',
        icon: 'group-open',
        route: '/customers',
        canRead: false,
        showInMenu: true,
        modules: [
            {
                id: 'fb64921b-1b0d-4f70-bcef-9f88ed141290',
                name: 'CUSTOMER_DETAILS',
                label: 'Customer_Detail.Details',
                route: 'details',
                canRead: false,
                showInMenu: true
            },
            {
                id: '57b85866-ed18-4828-835a-cb70edb8dca0',
                name: 'CUSTOMER_TICKETS',
                label: 'Customer_Detail.Tickets',
                route: 'tickets',
                canRead: false,
                showInMenu: true
            },
            {
                id: '8bd22e32-ec79-4e53-86a8-756fe4be4d04',
                name: 'CUSTOMER_SUBSCRIPTIONS',
                label: 'General.Subscriptions',
                route: 'season-tickets',
                canRead: false,
                showInMenu: true
            },
            {
                id: 'ST.CUSTOMER_PRODUCTS',
                name: 'CUSTOMER_PRODUCTS',
                label: 'General.Products',
                route: 'products',
                canRead: false,
                showInMenu: true
            },
            {
                id: '5c23f0fe-2dea-4570-a6d3-e57356f2ba85',
                name: 'CUSTOMER_ORDERS',
                label: 'Customer_Detail.Orders',
                route: 'orders',
                canRead: false,
                showInMenu: true
            },
            {
                id: 'f87e2470-852d-4cf0-b94e-440ecf01a64f',
                name: 'CUSTOMER_SETTINGS',
                label: 'General.Settings',
                route: 'settings',
                canRead: false,
                showInMenu: true
            }
        ]
    },
    {
        id: 'daf04ddc-c21e-4709-98ec-f34e7e9d42dc',
        name: 'SHOPS',
        label: 'Menu.Shops',
        icon: 'store',
        route: '/shops',
        canRead: false,
        showInMenu: true,
        modules: [
            {
                id: 'ST.SHOP_SETTINGS',
                name: 'SHOP_SETTINGS',
                label: 'Shop_Detail.Settings',
                route: 'settings',
                canRead: true,
                showInMenu: true
            },
            {
                id: 'ST.SHOP_PAGES',
                name: 'SHOP_PAGES',
                label: 'Shop_Detail.Pages',
                route: 'pages',
                canRead: true,
                showInMenu: true
            }
        ]
    },
    {
        id: 'eda7e00a-84a4-4f5b-9806-4644a8390bd5',
        name: 'REPORTS',
        label: 'Menu.Reports',
        icon: 'file',
        route: '/reports',
        canRead: false,
        showInMenu: true
    },
    {
        id: 'ST.EMPLOYEE',
        name: 'EMPLOYEE',
        label: 'Menu.Employee',
        icon: 'security',
        route: '/employee',
        position: 'bottom',
        canRead: false,
        showInMenu: true,
        modules: [
            {
                id: 'ST.EMPLOYEE.COST_ITEM_AGREEMENTS',
                name: 'COST_ITEM_AGREEMENTS',
                label: 'Menu.Cost_Item_Agreements',
                route: 'cost-item-agreements',
                canRead: false,
                showInMenu: true
            },
            {
                id: 'ST.EMPLOYEE.FINANCE_REPORTS',
                name: 'FINANCE_REPORTS',
                label: 'Menu.Finance_Reports',
                route: 'finance',
                canRead: true,
                showInMenu: true
            },
            {
                id: 'ST.EMPLOYEE.ADVANCED_SCAN_SERVICES',
                name: 'ADVANCED_SCAN_SERVICES',
                label: 'Menu.Advanced_Scan_Services',
                route: 'advanced-scan-services',
                canRead: false,
                showInMenu: true
            },
            {
                id: 'ST.EMPLOYEE.COPY_VENUE',
                name: 'COPY_VENUE',
                label: 'Menu.Copy_Venue',
                route: 'copy-venue',
                canRead: false,
                showInMenu: true
            }
        ]
    },
    {
        id: '11e30670-7ec8-422c-8cd5-45da8d891d66',
        name: 'PRODUCTS',
        label: 'Menu.Products',
        icon: 'gift-card',
        route: '/product-types',
        canRead: true
    },
    {
        id: '810cc45f-2cba-4199-b695-e5011bce8e06',
        name: 'DISCOUNT_CAMPAIGNS',
        label: 'Menu.Discount_Campaigns',
        icon: 'euro',
        route: '/discount-campaigns',
        canRead: false,
        showInMenu: false
    },
    {
        id: '0ed568cb-2117-470a-b3d4-57d6a60da849',
        name: 'VENUES',
        label: 'Menu.Venues',
        icon: 'location',
        route: '/venues',
        canRead: false,
        showInMenu: false
    },
    {
        id: '02c55be0-a64d-4809-98eb-a88c92bb325f',
        name: 'SETTINGS',
        label: 'Menu.Settings',
        icon: 'settings',
        route: '/settings',
        position: 'bottom',
        canRead: false,
        showInMenu: true
    }
];
